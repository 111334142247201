<template>
  <div>
    <v-card-title class="pt-0 pl-0" style="color: #000"
      >Danh sách báo giá</v-card-title
    >
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.request_type`]="{ item }">
        <span>{{ getSourceName(requestTypeList, item.request_type) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="`badge ${item.status && styleStatus[item.status]}`">{{
          getSourceName(statusList, item.status)
        }}</span>
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <span>{{
          item.parent ? item.parent.order + "-" + item.order : item.order
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="print(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-printer </v-icon>
                  In báo giá</v-list-item-title
                >
              </v-list-item> -->
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa báo giá</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </div>
</template>
<script>
// getProductPrices,
import { deleteProductPrices, getProductPrices } from "@/api/baogia";
export default {
  props: ["id", "name"],
  data: () => ({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    tableData: [],
    productTypesData: [],
    btnLoading: false,
    menu: {},
    loading: false,
    headers: [
      {
        text: "STT",
        align: "center",
        sortable: false,
        value: "stt",
      },
      {
        text: "Mã báo giá",
        align: "start",
        sortable: false,
        value: "price_code",
      },
      { text: "Ngày báo giá", value: "price_date" },
      { text: "Người tạo báo giá", value: "price_by" },
      { text: "Số điện thoại người tạo báo giá", value: "price_by_phone" },
      // { text: "Tên khách hàng", value: "customer_name" },
      { text: "Địa chỉ khách hàng", value: "address" },
      { text: "Trạng thái báo giá", value: "status" },
      { text: "Ghi chú", value: "description" },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "150",
      },
    ],
    requestTypeList: [
      { value: 1, name: "Yêu cầu báo giá mới" },
      { value: 2, name: "Yêu cầu báo giá thay thế" },
    ],
    styleStatus: {
        1: "bg-info",
        2: "bg-warning",
        3: "bg-success",
        4: "bg-primary",
        5: "bg-gray",
    },
    statusList: [
      { value: 1, name: "Mới tạo" },
      { value: 2, name: "Đã duyệt nội bộ" },
      { value: 3, name: "Khách hàng đã duyệt" },
      { value: 4, name: "Đã lập hợp đồng" },
      { value: 5, name: "Hủy Bỏ" },
    ],
  }),
  created() {
    this.getAllList();
    // for (let index = 0; index < 10; index++) {
    //   let date = new Date(
    //     +new Date() - Math.floor(Math.random() * 10000000000)
    //   );
    //   this.tableData.push({
    //     id: 0,
    //     price_code: `BG0${index}`,
    //     price_date: date.toISOString().slice(0, 10),
    //     price_by: `Trịnh Quang Minh`,
    //     price_by_phone: `098235673${index}`,
    //     customer_name: this.name,
    //     address: null,
    //     status: Math.floor(Math.random() * 4) + 1,
    //     description: null,
    //   });
    // }
  },
  methods: {
    async getAllList() {
      this.loading = true;
      let data = await getProductPrices({
        page: this.page,
        perPage: this.itemsPerPage,
        customer_id: this.id,
      });
      this.loading = false;
      this.tableData = data.data.map((x, i) => ({ stt: i + 1, ...x }));
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    createItem() {
      this.$router.push({ path: "/kinhdoanh/thembaogia" });
    },
    goEdit(item) {
      this.$router.push({ path: `/kinhdoanh/thembaogia/${item.id}` });
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa báo giá" +
          "<strong>" +
          item.price_code +
          " ?" +
          "</strong>",
        action: () => deleteProductPrices({ id: item.id, status: item.status }),
        onDone: this.getAllList,
      });
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
  },
};
</script>
<style scoped>
</style>