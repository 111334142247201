<template>
  <div>
    <v-card-title class="pt-0 pl-0" style="color: #000"
      >Biên bản nghiệm thu</v-card-title
    >
    <TableBienBanNghiemThu
      :customer_id="id"
      :isShowListTotal="isShowListTotal"
    />
  </div>
</template>
<script>
import TableBienBanNghiemThu from "@/pages/Quanlykinhdoanh/BienBanNghiemThu/table";
export default {
  props: ["id"],
  components: {
    TableBienBanNghiemThu,
  },
  data() {
    return {
      isShowListTotal: false,
    };
  },

  methods: {},
};
</script>
<style scoped>
</style>