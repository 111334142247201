<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-card-title>Kết quả tìm kiếm với mã số thuế {{ tax }}</v-card-title>
      <v-card-text
        ><strong>Tên khách hàng:</strong> {{ info.name }}</v-card-text
      >
      <v-card-text><strong>Địa chỉ:</strong> {{ info.address }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          Từ chối
        </v-btn>
        <v-btn color="green darken-1" text @click="fillData">
          Đồng ý lấy thông tin
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KHACHHANG from "@/api/khachhang";
export default {
  data: () => {
    return {
      dialog: false,
      tax: undefined,
      info: {
        id: "",
        name: "",
        address: "",
      },
    };
  },
  methods: {
    open(tax) {
      this.dialog = true;
      this.tax = tax;
      this.getInfoCustomer(tax);
    },
    async getInfoCustomer(tax) {
      const res = await KHACHHANG.searchInfoByTax(tax);
      if (res.data) {
        this.info = res.data;
      }
    },
    fillData() {
      this.dialog = false;
      this.$emit("GetInfo", this.info);
    },
  },
};
</script>

<style lang="scss" scoped></style>
