<template>
  <div>
    <v-card-title class="pt-0 pl-0" style="color: #000"
      >Danh sách hợp đồng</v-card-title
    >
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.contract_type`]="{ item }">
        <span>{{ getSourceName(contractsList, item.contract_type) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="`badge ${item.status && styleStatus[item.status]}`">{{
          getSourceName(statusList, item.status)
        }}</span>
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <span>{{
          item.parent ? item.parent.order + "-" + item.order : item.order
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="print(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-printer </v-icon>
                  In yêu cầu báo giá</v-list-item-title
                >
              </v-list-item> -->
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </div>
</template>
<script>
import { deleteContracts, getContracts } from "@/api/hopdong";
import { getAllContractTypes } from "@/api/loaihopdong";
import { STYLE_STATUS, STATUS_LIST } from "@/constants/hopdong";
export default {
  props: ["id", "name"],
  data: () => ({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    tableData: [],
    loading: false,
    contractsList: [],
    headers: [
      {
        text: "STT",
        align: "center",
        sortable: false,
        value: "stt",
      },
      {
        text: "Mã hợp đồng",
        align: "start",
        sortable: false,
        value: "contract_code",
      },
      { text: "Ngày hiệu lực", value: "contract_date" },
      { text: "Ngày hết hạn", value: "contract_due_date" },
      { text: "Loại hợp đồng", value: "contract_type" },
      // { text: "Khách hàng", value: "customer_name" },
      // { text: "Đại diện khách hàng", value: "customer_agent_name" },
      {
        text: "Chức vụ đại diện",
        value: "customer_agent_position",
      },
      { text: "Mã số thuế", value: "customer_tax" },
      // { text: "Địa chỉ khách hàng", value: "customer_address" },
      { text: "Số điện thoại", value: "customer_phone" },
      { text: "Trạng thái", value: "status" },
      // { text: "Ghi chú", value: "description" },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "150",
      },
    ],
    styleStatus: STYLE_STATUS,
    statusList: STATUS_LIST,
  }),
  computed: {},
  created() {
    this.getContractsList();
    // for (let index = 0; index < 10; index++) {
    //   let date = new Date(
    //     +new Date() - Math.floor(Math.random() * 10000000000)
    //   );
    //   let cloneDate = new Date(date.getTime());
    //   cloneDate.setDate(cloneDate.getDate() + 10);
    //   this.tableData.push({
    //     id: index + 1,
    //     contract_code: `HD-A${index}`,
    //     contract_date: date.toISOString().slice(0, 10),
    //     contract_due_date: cloneDate.toISOString().slice(0, 10),
    //     contract_type: null,
    //     customer_name: this.name,
    //     customer_agent_position: null,
    //     customer_tax: null,
    //     customer_phone: `097766253${index}`,
    //     status: Math.floor(Math.random() * 2) + 1,
    //   });
    // }
    this.getData();
  },
  methods: {
    changePage(val) {
      this.page = val;
      this.getData();
    },
    async getContractsList() {
      await getAllContractTypes().then((res) => {
        this.contractsList = res.map((item) => ({
          value: item.id,
          name: item.name,
        }));
      });
    },
    async getData() {
      this.loading = true;
      const res = await getContracts({
        page: this.page,
        perPage: this.itemsPerPage,
        customer_id: this.id,
      });
      this.tableData = [...res.data].map((x, i) => {
        let status_due_date = this.compareDate(x.contract_due_date);
        if (status_due_date == 1) {
          x.alert = true;
        } else if (status_due_date == 2) {
          x.status = 3;
        }
        return { stt: i + 1, ...x };
      });

      this.pageCount = res.last_page;
      this.loading = false;
    },
    compareDate(date) {
      const today = new Date();
      date = new Date(date);
      const difference = date - today;
      let days;
      days = difference / (1000 * 3600 * 24);
      // console.log(days);
      if (days <= 7 && days > 0) {
        return 1;
      } else if (days < 0) {
        return 2;
      }
      return 3;
    },
    goEdit(item) {
      this.$router.push({ path: `/kinhdoanh/themhopdong/${item.id}` });
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa hợp đồng " +
          "<strong>" +
          item.contract_code +
          " ?" +
          "</strong>",
        action: () => deleteContracts({ id: item.id, status: item.status }),
        onDone: this.getData,
      });
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
  },
};
</script>
<style scoped>
</style>