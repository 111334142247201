import request from "../utils/request";

export function getCustomerTypes(params) {
    return request({
        url: "customertypes",
        method: "get",
        params,
    });
}

export function getAllCustomerTypes() {
    return request({
        url: "allcustomertypes",
        method: "get",
    });
}

export function addCustomerTypes(data) {
    return request({
        url: "customertypes",
        method: "post",
        data,
    });
}

export function editCustomerTypes(data) {
    return request({
        url: "customertypes",
        method: "put",
        data,
    });
}

export function deleteCustomerTypes(data) {
    return request({
        url: "/customertypes",
        method: "delete",
        data
    });
}



//khach hang dưng hoat dong
export function geAlltCustomerDeleted() {
    return request({
        url: "allcustomerdeleted",
        method: "get",
    });
}

