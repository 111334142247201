<template>
  <div>
    <v-card-title class="pt-0 pl-0" style="color: #000"
      >Thông tin khách hàng
    </v-card-title>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.image`]="{ item }">
        <img
          height="50px"
          width="50px"
          :src="item.image && getImageUrl(item.image.link)"
          v-if="item.image"
        />
      </template>
      <template v-slot:[`item.unit_price`]="{ item }">
        {{ formatnumber(item.unit_price) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click="editItem(item)" title="Cập nhật">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </CustomTable>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Cập nhật Sản phẩm</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form_detail">
              <v-row>
                <v-col xs="8" sm="8" md="8">
                  <div class="label-form">Tên sản phẩm</div>
                  <v-text-field
                    placeholder="Tên sản phẩm"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    v-model="editedItem.ten"
                    :rules="nameProductRules"
                  ></v-text-field>
                </v-col>
                <v-col xs="4" sm="4" md="4">
                  <div class="label-form">Mã sản phẩm</div>
                  <v-text-field
                    placeholder="Mã sản phẩm"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    v-model="editedItem.ma"
                    :rules="codeProductRules"
                  ></v-text-field>
                </v-col>

                <v-col xs="4" sm="4" md="4">
                  <div class="label-form">Đơn vị tính</div>
                  <v-text-field
                    placeholder="Đơn vị tính"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    v-model="editedItem.don_vi_tinh"
                    :rules="unitProductRules"
                  ></v-text-field>
                </v-col>
                <v-col xs="4" sm="4" md="4">
                  <div class="label-form">Loại sản phẩm</div>
                  <v-select
                    prepend-inner-icon="mdi-image-area"
                    v-model="editedItem.loai_chat_thai_id"
                    :items="productTypesList"
                    outlined
                    dense
                    item-text="ten"
                    item-value="id"
                    :rules="request_typeRules"
                  >
                  </v-select>
                </v-col>

                <v-col xs="4" sm="4" md="4">
                  <div class="label-form">Quy đổi sang kg</div>
                  <v-text-field
                    placeholder="Quy đổi sang kg"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                    v-model="editedItem.quy_doi"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="12">
                  <div class="label-form">Mô tả</div>
                  <v-textarea
                    v-model="editedItem.mo_ta"
                    placeholder="Mô tả"
                    outlined
                    dense
                    prepend-inner-icon="mdi-note"
                    no-resize
                    height="200px"
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" text @click="dialog = false"> Đóng </v-btn>
          <v-btn color="success" @click="save">
            <v-icon left>mdi-plus</v-icon>
            Lưu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//old
// import { getAllProducts, editProducts } from "@/api/sanpham";
// import { getAllProductTypes } from "@/api/loaisanpham";
//new
import chatThaiAPI from "@/api/sx.chatthai.api";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";

// import JsonToFromData from "@/utils/JsonToFormData.js";
import { numberFormat } from "@/utils/moneyFormat";
export default {
  props: ["id"],
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      data: [],
      btnLoading: false,
      menu: {},
      loading: false,
      dialog: false,
      editedItem: {},
      search: "",
      productTypesList: [],
      codeProductRules: [(v) => !!v || "Mã sản phẩm không thể bỏ trống"],
      nameProductRules: [(v) => !!v || "Tên sản phẩm không thể bỏ trống"],
      unitProductRules: [(v) => !!v || "Đơn giá không thể bỏ trống"],
      request_typeRules: [(v) => !!v || "Loại không thể bỏ trống"],
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        {
          text: "STT",
          align: "center",
          sortable: false,
          value: "stt",
          width: "20px",
        },
        { text: "Tên hàng hoá", value: "ma" },
        { text: "Loại hàng hoá", value: "loai_chat_thai.ten" },
        { text: "Đơn vị", value: "don_vi_tinh" },
        { text: "Quy đổi sang kg", value: "quy_doi" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {
    tableData() {
      return this.data.map((x, i) => ({
        stt: i + 1,
        ...x,
      }));
    },
  },
  created() {
    this.getAllList();
    this.getProductTypesList();
  },
  methods: {
    async getProductTypesList() {
      let res = await loaiChatThaiAPI.list();
      this.productTypesList = res?.data;
    },
    async getAllList() {
      try {
        this.loading = true;
        const res = await chatThaiAPI.list({ customer_id: this.id });
        this.data = res.data;
        this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
      } finally {
        this.loading = false;
      }
    },
    changePage(val) {
      this.page = val;
      // this.getAllList();
    },
    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },
    async save() {
      this.$refs.form_detail.validate();
      if (this.$refs.form_detail.validate()) {
        let data = { ...this.editedItem };
        data.type = data.type && parseInt(data.type);
        await chatThaiAPI.update(data.id, data);
        this.alert(false);
        this.dialog = false;
        this.getAllList();
      }
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$toast.info(`${isCreate ? "Thêm mới" : "Cập nhật"} thành công`, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    deleteItem(item) {
     
    },
    formatnumber(number) {
      return numberFormat(number);
    },
  },
};
</script>
<style scoped>
</style>