<template>
  <div class="flex-grow-1" style="width: 100%;">
    <table style="width: 100%;">
      <thead>
        <tr>
          <th>ID</th>
          <th>Mã</th>
          <th>Tên khách hàng</th>
          <th>Tên viết tắt</th>
          <th>Số điện thoại</th>
          <th>Mã số thuế</th>
          <th>Mã QLCTNH</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in getKeyAccess(khach_hang_sx)"
          :key="`field_${index}`"
        >
          <td
            v-for="([key, value], i) of convertItem(item)"
            :key="`field_${key}_${i}`"
            :class="{ width50: key == '' }"
          >
            <span v-if="index != index_update">
              <span style="width: auto" class="mx-1" :title="value">
                {{ value }}
              </span>
            </span>
            <span v-else>
              <v-text-field
                :disabled="key == 'id'"
                v-model="form_edit[key]"
                :label="getFieldWithKey(key)"
                outlined
                dense
                class="mx-1"
                hide-details
                :style="key == 'id' ? 'width: 60px' : 'width: auto'"
              />
            </span>
          </td>
          <td>
            <div class="d-flex align-center">
              <v-btn
                small
                hide-details
                color="primary"
                icon
                @click="onAction('UPDATE', index, item)"
                v-if="index != index_update"
                title="Cập nhật"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                hide-details
                color="success"
                icon
                @click="onUpdateItem(index)"
                v-if="index == index_update"
                title="Thực hiện cập nhật"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                hide-details
                color="primary"
                icon
                @click="rollback(index)"
                v-if="index == index_update"
                title="Thoát"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ACTION_TABLE } from "@/constants/_action_table";
import { getAreas } from "@/api/khuvuc";
import { COORDINATE_DEFAULT } from "@/constants/_coordinate";
import API from "@/api/sx.khachhang.api.js";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    customer_id: {
      type: [Number, String],
      default: null,
    },
    editing: {
      type: Boolean,
    },
    khach_hang_kd: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    local: "",
    index_update: -1,
    form_edit: {
      id: null,
      ma: null,
      ten: "",
      so_dien_thoai: "",
      ma_so_thue: "",
      ma_qlctnh: "",
    },
    form: {
      id: null,
      ma: null,
      ten: "",
      so_dien_thoai: "",
      ma_so_thue: "",
      ma_qlctnh: "",
    },
  }),
  computed: {
    khach_hang_sx: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    _actions() {
      return ACTION_TABLE;
    },
  },
  methods: {
    async onPushKH() {
      let index_field = this.check_validete_form();
      if (index_field) {
        return this.errorNotify(this.notifyVaildate(index_field));
      }
      let data_create = Object.assign({}, this.form);
      if (this.editing) {
        data_create.customer_id = this.customer_id;
        const response = await API.create(data_create);
        if (response?.data) {
          this.khach_hang_sx.push(response.data);
        }
      } else {
        this.khach_hang_sx.push(data_create);
      }
      this.form = this.$options.data.call(this).form;
    },
    check_validete_form() {
      let [index, arr] = [0, Object.values(this.form)];
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i] && (i == 1 || i == 2)) {
          index = i + 1;
          break;
        }
      }
      return index;
    },
    notifyVaildate(number) {
      switch (number) {
        case 2:
          return "Mã khách hàng là bắt buộc";
        case 3:
          return "Tên khách hàng là bắt buộc";
        default:
          return "Pass";
      }
    },
    errorNotify(message) {
      this.$toast.error(message, {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    async onAction(action, index, item) {
      if (action == "UPDATE") {
        this.form_edit = Object.assign({}, item);
        this.index_update = index;
      } else if (action == "DELETE") {
        this.confirmDelete(index);
      }
    },
    confirmDelete(index) {
      this.$confirmBox.show({
        title: "Xóa khách hàng sản xuất",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa khách hàng này không?",
        action: async () => this.handleDelete(index),
        // onDone: () => ({}),
      });
    },
    async handleDelete(index) {
      if (this.editing) {
        const response = await API.destroy(this.khach_hang_sx[index].id);
        if (response.isDone) {
          this.khach_hang_sx.splice(index, 1);
        }
        this.khach_hang_sx.splice(index, 1);
      } else {
        this.khach_hang_sx.splice(index, 1);
      }
    },
    async onUpdateItem(index) {
      const data = Object.assign(this.khach_hang_sx[index], this.form_edit);
      if (this.editing) {
        const response = await API.update(data.id, data);
        if (response.isDone) {
          this.khach_hang_sx[index] = data;
        }
      } else {
        this.khach_hang_sx[index] = data;
      }
      this.index_update = this.$options.data.call(this).index_update;
    },
    getFieldWithKey(key) {
      switch (key) {
        case "id":
          return "ID";
        case "ma":
          return "Mã";
        case "ten":
          return "Tên";
        case "so_dien_thoai":
          return "Số điện thoại";
        case "ma_so_thue":
          return "Mã số thuế";
        case "ma_qlctnh":
          return "Mã QLCTNH";
        case "ten_ngan":
          return "Tên ngắn";
        default:
          return "Label";
      }
    },
    convertItem(item) {
      const tempt = Object.assign({}, item);
      return Object.entries(tempt);
    },
    getKeyAccess(data) {
      return data.map((x) => ({
        id: x.id,
        ma: x.ma,
        ten: x.ten,
        ten_ngan: x.ten_ngan,
        so_dien_thoai: x.so_dien_thoai,
        ma_so_thue: x.ma_so_thue,
        ma_qlctnh: x.ma_qlctnh,

      }));
    },
    rollback() {
      this.index_update = this.$options.data.call(this).index_update;
    },
    getAreaText({ name, district, province }) {
      if (!name) return null;
      if (!district) return name;
      if (!province) return `${name} / ${district?.name}`;
      return `${name} / ${district?.name} / ${province?.name}`;
    },
  },
};
</script>
<style scoped>
table {
  border: 1px solid #bbb;
  border-collapse: collapse;
  font: 16px "Lucida Grande", "Helvetica", "Arial", sans-serif;
  margin-bottom: 15px;
}
th,
td {
  border: 1px solid #bbb;
  padding: 5px 7px;
  text-align: left;
  /* width: 100px; */
  overflow: hidden;
}
th {
  font-size: 12px !important;
  font-weight: 200 !important;
}
</style>
