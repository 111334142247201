export const Status = [
	{
		id: 1,
		name:'Khách hàng cũ'
	},
	{
		id: 2,
		name:'Khách hàng hiện tại'
	},
	{
		id: 4,
		name:'Khách hàng tiềm năng'
	},
]

export const StatusStyle = {

}