<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="12">
        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-account
            </v-icon>
            <span class="text-h6 font-weight-light">Thông tin khách hàng</span>
            <span
              style="position: absolute; right: 20px ;"
              class="text-h6 font-weight-light"
              >ID: {{ form.id }}</span
            >
          </v-card-title>
          <div class="px-5">
            <v-text-field
              v-model="form.name"
              label="Tên khách hàng"
              :rules="nameRules"
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              label="Số điện thoại"
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.code"
              label="Mã khách hàng"
              filled
              dense
              :rules="codeRules"
            ></v-text-field>
            <v-select
              v-model="form.type"
              :items="CustomerTypesList"
              filled
              dense
              label="Nhóm khách hàng"
              item-text="name"
              item-value="id"
            >
            </v-select>
            <v-text-field
              v-model="form.email"
              label="Email khách hàng"
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.tax"
              label="Mã số thuế"
              filled
              dense
              :rules="thueRules"
              append-outer-icon="mdi-magnify"
              @click:append-outer="SearchCustomer"
            ></v-text-field>
            <v-text-field
              v-model="form.address"
              label="Địa chỉ hóa đơn"
              filled
              dense
              :rules="diachiRules"
            ></v-text-field>
            <v-text-field
              v-model="form.fax"
              label="Fax"
              filled
              dense
            ></v-text-field>
          </div>
        </v-card>

        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-credit-card-scan
            </v-icon>
            <span class="text-h6 font-weight-light">Thông tin ngân hàng</span>
          </v-card-title>
          <div class="px-5">
            <v-autocomplete
              v-model="form.bank_id"
              :items="danhSachNganHang"
              filled
              dense
              :item-text="(item) => `${item.shortName} - ${item.vn_name}`"
              item-value="id"
              label="Tên ngân hàng"
            />
            <v-text-field
              v-model="form.account_number"
              label="Số tài khoản"
              filled
              dense
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.branch"
              label="Chi nhánh ngân hàng"
              filled
              dense
            ></v-text-field>
          </div>
        </v-card>
        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-barcode
            </v-icon>
            <span class="text-h6 font-weight-light">Mã chất thải</span>
          </v-card-title>
          <div class="px-5">
            <v-text-field
              v-model="form.misa_code_pl"
              label="Mã Misa PL"
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.misa_code_ct"
              label="Mã Misa CT"
              filled
              dense
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="12">
        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-map
            </v-icon>
            <span class="text-h6 font-weight-light">Địa chỉ lấy hàng</span>
          </v-card-title>
          <div class="px-5">
            <tabale-list
              v-model="form.product_adds"
              :customer_id="form.id"
              :editing="editing"
              :id_khsx_lists="form.khach_hang_s_xs"
            />
          </div>
        </v-card>
        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-account-hard-hat
            </v-icon>
            <span class="text-h6 font-weight-light"
              >Mã khách hàng sản xuất</span
            >
          </v-card-title>
          <div class="px-5" style="width: 100%;">
            <khachHangSx
              v-model="form.khach_hang_s_xs"
              :customer_id="form.id"
              :editing="editing"
              :khach_hang_kd="form"
            ></khachHangSx>
          </div>
        </v-card>
        <v-card outlined class="mx-auto mb-5">
          <v-card-title>
            <v-icon left>
              mdi-account-check
            </v-icon>
            <span class="text-h6 font-weight-light">Người phụ trách</span>
          </v-card-title>
          <div class="px-5">
            <v-autocomplete
              v-model="form.person_in_charge_id"
              :items="ListEmployee"
              filled
              dense
              label="Kinh doanh khu vực"
              item-text="name"
              item-value="id"
            />
            <v-autocomplete
              v-model="form.logistic_person_id"
              :items="ListNVHC"
              filled
              dense
              label="Nhân viên hậu cần"
              item-text="name"
              item-value="id"
            />
            <v-text-field
              v-model="form.agent_name"
              label="Người đại diện"
              filled
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.agent_position"
              label="Chức vụ người đại diện"
              filled
              dense
            ></v-text-field>
          </div>
        </v-card>
        <v-card outlined class="mx-auto pb-5">
          <v-card-title>
            <v-icon left>
              mdi-information-outline
            </v-icon>
            <span class="text-h6 font-weight-light">Thông tin bổ sung</span>
          </v-card-title>
          <div class="px-5">
            <v-textarea
              v-model="form.description"
              label="Ghi chú"
              filled
              dense
              no-resize
              hide-details
            ></v-textarea>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <SearchInfo ref="searchByTax" @GetInfo="GetInfoCustomer"></SearchInfo>
  </div>
</template>
<script>
import SearchInfo from "./DialogSearchCustomer.vue";
import khachHangSx from "@/pages/Quanlykhachhang/components/khach-hang-sx";
export default {
  components: {
    khachHangSx,
    SearchInfo,
    tabaleList: () => import("../../components/table-list.vue"),
  },
  props: {
    loading: Boolean,
    value: { type: Object, default: () => ({}) },
    CustomerTypesList: { type: Array, default: () => [] },
    customerStatus: { type: Array, default: () => [] },
    danhSachNganHang: { type: Array, default: () => [] },
    editing: Boolean,
    ListEmployee: { type: Array, default: () => [] },
    ListNVHC: { type: Array, default: () => [] },
    khuCongNghiepList: { type: Array, default: () => [] },
    provinceList: { type: Array, default: () => [] },
    districtList: { type: Array, default: () => [] },
    regionList: {
      type: Array,
      default: () => [
        "Việt Nam",
        "Hàn Quốc",
        "Trung quốc",
        "Anh",
        "Nhật bản",
        "Mỹ",
      ],
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  data: () => ({
    nameRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã khách hàng không thể bỏ trống"],
    misaPlRules: [(v) => !!v || "Mã misa phế liệu không thể bỏ trống"],
    misaCTRules: [(v) => !!v || "Mã misa chất thải không thể bỏ trống"],
    addRules: [(v) => !!v || "Địa chỉ không thể bỏ trống"],
    thueRules: [(v) => !!v || "Mã số thuế không thể bỏ trống"],
    diachiRules: [(v) => !!v || "Địa chỉ xuất hóa đơn không thể bỏ trống"],
    emailRules: [
      (v) => !!v || "E-Mail không thể bỏ trống",
      (v) => /.+@.+\..+/.test(v) || "E-mail không hợp lệ",
    ],
    key: [
      {
        value: "",
        text: "",
      },
    ],
  }),
  methods: {
    SearchCustomer() {
      this.$refs.searchByTax.open(this.form.tax);
    },
    GetInfoCustomer(info) {
      this.form.name = info.name;
      this.form.address = info.address;
    },
  },
};
</script>
