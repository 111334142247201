export const ACTION_TABLE = [
  {
    code: "UPDATE",
    title: "Sửa",
    icon: "mdi-pencil",
    emit: "on:Update",
    color: "primary",
  },
  {
    code: "DELETE",
    title: "Xóa",
    icon: "mdi-delete",
    emit: "on:Delete",
    color: "error",
  },
];
