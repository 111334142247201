<template>
  <PageListLayout
    icon="mdi-map-marker-outline"
    :title="title"
    noFilter
    useBack
    linkBack="/quanlykhachhang/danhsachkhachhang"
  >
    <template slot="side-right">
      <v-btn
        v-if="tab == 0"
        class="mr-4"
        depressed
        color="success"
        @click="submit"
      >
        <template v-if="editing">
          <v-icon left>mdi-account-edit </v-icon>
          Cập nhật
        </template>

        <template v-else>
          <v-icon left>mdi-account-plus </v-icon>
          Thêm mới
        </template>
      </v-btn>
    </template>
    <v-tabs v-if="editing" color="var(--primary-color)" v-model="tab">
      <v-tab>THÔNG TIN CƠ BẢN</v-tab>
      <v-tab>HỢP ĐỒNG</v-tab>
      <v-tab>BÁO GIÁ</v-tab>
      <!-- <v-tab>THÔNG TIN HÀNG HOÁ</v-tab> -->
      <v-tab>BIÊN BẢN NGHIỆM THU</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <v-card-text
              ><Info
                :danhSachNganHang="danhSachNganHang"
                :loading="loadingSubmit"
                v-model="data"
                :key="`ttcb-${tab}`"
                :CustomerTypesList="customerTypesData"
                :customerStatus="Status"
                :ListEmployee="ListEmployee"
                :ListNVHC="ListNVHC"
                :editing="editing"
                :khuCongNghiepList="khuCongNghiepList"
              ></Info
            ></v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text
            ><HopDong :name="data ? data.name : ''" :id="id"></HopDong
          ></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text
            ><BaoGia
              :name="data ? data.name : ''"
              :id="id"
              :key="`bg-${tab}`"
            ></BaoGia
          ></v-card-text>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-card flat>
          <v-card-text
            ><ThongTinHangHoa :id="id" :key="`tthh-${tab}`"></ThongTinHangHoa
          ></v-card-text>
        </v-card>
      </v-tab-item> -->
      <v-tab-item>
        <v-card flat>
          <v-card-text
            ><BienBanNghiemThu :id="id" :key="`bbnt-${tab}`"></BienBanNghiemThu
          ></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </PageListLayout>
</template>
<script>
import KHACHHANG from "@/api/khachhang";
import Info from "./Detail/Info";
import ThongTinHangHoa from "./Detail/ThongTinHangHoa";
import BaoGia from "./Detail/BaoGia";
import HopDong from "./Detail/HopDong";
import BienBanNghiemThu from "./Detail/BienBanNghiemThu";
import { getAllCustomerTypes } from "@/api/loaikhachhang";
import { Status } from "@/constants/khachhang";
import { getAllAreas } from "@/api/khuvuc";
import { index as getDanhSachNganHang } from "@/api/nganhang.api";
import { getNhanVienKinhDoanh, getNhanVienHauCan } from "@/api/nhanvien";
import { index as fetchKhuCongNghiep } from "@/api/khucongnghiep.api";
import { index as fetchProvince } from "@/api/province.api";
import { index as fetchDistrict } from "@/api/district.api";
export default {
  props: ["id"],
  components: {
    Info,
    // ThongTinHangHoa,
    BaoGia,
    HopDong,
    BienBanNghiemThu,
  },
  data: () => ({
    loading: false,
    data: {
      product_adds: [],
      name: "",
      address: "",
    },
    tab: 0,
    customerTypesData: [],
    Status,
    editing: false,
    loadingSubmit: false,
    danhSachNganHang: [],
    ListEmployee: [],
    ListNVHC: [],
    khuCongNghiepList: [],
    provinceList: [],
    districtList: [],
  }),
  computed: {
    title() {
      if (this.editing) {
        return "Cập nhật khách hàng";
      } else {
        return "Thêm mới khách hàng";
      }
    },
  },
  mounted() {
    if (this.id) {
      this.editing = true;
    }
    if (this.editing) {
      this.loading = true;
      Promise.all([
        this.getData(),
        this.getAllCustomerTypes(),
        this.fetchDanhSachNganHang(),
        this.getAllEmployee(),
        this.getAllNVHC(),
        this.getKhuCongNghiep(),
        this.getProvince(),
        this.getDistrict(),
      ]).finally(() => {
        this.loading = false;
      });
    } else {
      this.loading = true;
      Promise.all([
        this.getAllCustomerTypes(),
        this.fetchDanhSachNganHang(),
        this.getAllEmployee(),
        this.getKhuCongNghiep(),
        this.getProvince(),
        this.getDistrict(),
      ]).finally(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    async getKhuCongNghiep() {
      try {
        const res = await fetchKhuCongNghiep({ pagination: false });
        this.khuCongNghiepList = res;
      } catch (e) {
        this.khuCongNghiepList = [];
      }
    },
    async getProvince() {
      try {
        const res = await fetchProvince({ pagination: false });
        this.provinceList = res?.data || [];
      } catch (e) {
        this.provinceList = [];
      }
    },
    async getDistrict() {
      try {
        const res = await fetchDistrict({ pagination: false });
        this.districtList = res?.data || [];
      } catch (e) {
        this.districtList = [];
      }
    },
    async getAllEmployee() {
      const res = await getNhanVienKinhDoanh();
      this.ListEmployee = res?.data;
    },
    async getAllNVHC() {
      const res = await getNhanVienHauCan();
      this.ListNVHC = res?.data;
      console.log(this.ListNVHC);
    },
    async fetchDanhSachNganHang() {
      const res = await getDanhSachNganHang({
        pagination: false,
      });
      this.danhSachNganHang = res;
    },
    async getAllCustomerTypes() {
      this.loading = true;
      let data = await getAllCustomerTypes();
      this.loading = false;
      this.customerTypesData = data;
    },
    async getData() {
      const res = await KHACHHANG.getDetail(this.id);
      this.data = Object.assign(this.data, res);
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.editing) {
          await this.updateInfo();
        } else {
          await this.addInfo();
        }
      }
    },
    async updateInfo() {
      this.loadingSubmit = true;
      const res = await KHACHHANG.update(this.data.id, this.data);
      this.loadingSubmit = false;
    },
    async addInfo() {
      this.loadingSubmit = true;
      const res = await KHACHHANG.create(this.data);
      if (res && res.isDone) {
        this.$router.push({ path: "/quanlykhachhang/danhsachkhachhang" });
      }
      this.loadingSubmit = false;
    },
  },
};
</script>
<style scoped>
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #000000f9;
}
/* .tab_color{
} */
/deep/ .style_main_content {
  margin-top: 0 !important ;
}
</style>
