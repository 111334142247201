import request from "../utils/request";
export function index(params = {}) {
  return request({
    url: "nganhang",
    method: "get",
    params,
  });
}

export function store(data) {
  return request({
    url: "nganhang",
    method: "post",
    data,
  });
}

export function update(id, data) {
  return request({
    url: `nganhang/${id}`,
    method: "patch",
    data,
  });
}

export function show(id) {
  return request({
    url: `nganhang/${id}`,
    method: "get",
  });
}

export function destroy(id) {
  return request({
    url: `nganhang/${id}`,
    method: "delete",
  });
}
